import axios from "axios";
import { message } from "ant-design-vue";
const qs = require("qs");

let path = location.protocol + process.env.VUE_APP_BASE_API;
// axios基本配置
// axios.defaults.timeout = 50000
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;
// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error && error.response) {
      console.log("error:" + error.response.status);
      responseError(error);
    }
    return Promise.reject(error);
  }
);

//错误执行提示
const responseError = function (err) {
  let newTime = new Date().getTime();
  let oldTime = 0;
  if (err.response.status == 401) {
    message.error("登录过期，请重新登录");
    let url = window.location.href,
      href = path + "/alpha/sso/redirect?redirectUri=" + encodeURI(url);
    window.location.href = href;
  } else if (err.response.status == 502 || err.response.status == 503) {
    oldTime = JSON.parse(sessionStorage.getItem("time"));
    if (oldTime + 500 < newTime) {
      message.error("系统发布中，请稍后刷新重试");
    }
  } else {
    message.error(err.response.data.message);
  }
  sessionStorage.setItem("time", JSON.stringify(newTime));
};

const http = {
  /**
   * post 请求方法
   * @param url
   * @param data
   * @returns {Promise}
   */
  post(url, data) {
    url = path + url;
    return new Promise((resolve, reject) => {
      axios.post(url, qs.stringify(data)).then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  /**
   * get 请求方法
   * @param url
   * @param data
   * @returns {Promise}
   */
  get(url, data) {
    url = path + url;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: data,
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
  /**
   * put 请求方法
   * @param url
   * @param id
   * @param data
   * @returns {Promise}
   */
  // put(url, id, data) {
  // 	return new Promise((resolve, reject) => {
  // 		axios.put(url + id, data).then(response => {
  // 			resolve(response)
  // 		}, err => {
  // 			reject(err)
  // 		})
  // 	})
  // },
  /**
   * delete 请求方法
   * @param url
   * @param id
   * @returns {Promise}
   */
  // delete(url, id) {
  // 	return new Promise((resolve, reject) => {
  // 		axios.delete(url + id).then(response => {
  // 			resolve(response)
  // 		}, err => {
  // 			reject(err)
  // 		})
  // 	})
  // },
  /**
   * post 请求方法  请求类型为application/json
   * @param url
   * @param data
   * @returns {Promise}
   */
  json_post(url, data) {
    url = path + url;
    return new Promise((resolve, reject) => {
      axios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
  files(url, params) {
    url = path + url;
    var data = new FormData();
    for (var key in params) {
      if (params[key]) {
        data.append(key, params[key]);
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post(url, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
};

export default http;
